"use strict";

/**
 * ! 设置本地存储
 * @param {string} 设置的 key 名称
 * @param {string object} 存储的值
 */
function localSetItem(key, val) {
	window.localStorage.setItem(key, window.JSON.stringify(val));
}

/**
 * ! 获取本地存储
 * @param {string} 获取的 key 名称
 */
function localGetItem(key) {
	let data = window.localStorage.getItem(key);
	try {
		return data ? window.JSON.parse(data) : null;
	} catch (error) {
		console.error(error);
		return "";
	}
}

/**
 * ! 删除本地存储
 * @param {string} 获取的 key 名称
 */
function localRemoveItem(key) {
	window.localStorage.removeItem(key);
}

export { localSetItem, localGetItem, localRemoveItem };
